import React, { useState, useEffect, useRef, useMemo } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./powerBi.css";
import { getPbiAccessToken } from "../Redux/actions/utility-actions";
import { connect, useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";
import _ from "lodash";

const PowerBi = ({ getPbiAccessToken, pbiAccessToken, pageName, id, dealerCode, wssCode, filterName }) => {
  const dispatch = useDispatch();
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  const userDetails = useSelector((state) => state?.userDetails?.details);
  const userEmail = userDetails?.Email;
  const title = userDetails?.Title;
  const nsmEmail = useSelector((state) => state?.HoUserSh7Code?.nsmEmail);
  const salesGroupCode = useSelector(
    (state) => state?.userDetails?.details?.Sales_Group_Code__c || state?.SelectedSalesGroupCode?.SalesGroupCode
  );
  const UserType = useSelector((state) =>
    state?.userType?.type !== "Other" ? state?.userType?.type : userDetails?.Title
  );
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);

  const [view, setView] = useState();
  const [columnName, setColumnName] = useState("");
  const [pbiFilter, setPbiFilter] = useState([]);
  const [emailTableName, setEmailTableName] = useState("DimHierarchy");
  const [reload, setReload] = useState(false);

  // Memoized PBI Email based on user type
  const pbiEmail = useMemo(() => {
    if (UserType === "HO Collaborator" && nsmEmail) {
      return [nsmEmail];
    }
    return userEmail ? [userEmail] : [];
  }, [UserType, nsmEmail, userEmail]);

  // Update column name based on UserType
  useEffect(() => {
    if (UserType) {
      const columnMap = {
        SH7: "SH7Email",
        SH6: "SH5Email",
        SH5: "SH5Email",
        SH4: "SH5Email",
        "HO Collaborator": "SH7Email",
        "RBDM Collaborator": "SH3Email",
        MH3: "SH3Email",
      };
      const newColumnName = columnMap[UserType] || "SH3Email";
      setColumnName(newColumnName);
    }
  }, [UserType]);

  useEffect(() => {
    // Generate filters whenever columnName or emailTableName changes
    if (columnName && emailTableName) {
      generateFilters(columnName, emailTableName);
    }
  }, [columnName, emailTableName]);

  useEffect(() => {
    if (filterName) {
      setEmailTableName(filterName);
    }
  }, [filterName]);

  useEffect(() => {
    // Initialize event listeners for token expiration
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  useEffect(() => {
    // Generate filters whenever wssCode or dealerCode changes
    if (columnName && emailTableName) {
      generateFilters(columnName, emailTableName);
    }
  }, [wssCode, dealerCode])

  const generateFilters = (columnName, emailTableName) => {
    const commonFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: { table: emailTableName, column: columnName },
      operator: "In",
      values: pbiEmail,
    };

    if (!_.isEmpty(wssCode)) {
      setPbiFilter([
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: { table: "DimCustomer", column: "BI_Customer Code" },
          operator: "EqualTo",
          values: [wssCode],
        },
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: { table: "DimCustomer", column: "customercode" },
          operator: "EqualTo",
          values: [wssCode],
        },
        commonFilter,
      ]);
    } else if (!_.isEmpty(dealerCode)) {
      setPbiFilter([
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: { table: "DimDealer_MS", column: "Dealer Code" },
          operator: "EqualTo",
          values: [dealerCode],
        },
        commonFilter,
      ]);
    } else if (pbiEmail.length > 0) {
      setPbiFilter([commonFilter]);
    } else {
      setPbiFilter([]);
    }
  };

  const isReadyToRender = useMemo(() => {
    return pbiAccessToken && view && pbiFilter.length > 0 && columnName;
  }, [pbiAccessToken, view, pbiFilter, columnName]);
  useEffect(() => {
    // Handle view layout and token retrieval
    const updateView = () => {
      if (window.matchMedia("(max-width: 600px)").matches) {
        setView(models.LayoutType.MobilePortrait);
      } else {
        setView(models.LayoutType.MobileLandscape);
      }
    };

    updateView();
    getPbiAccessToken();
    window.addEventListener("resize", updateView);
    return () => window.removeEventListener("resize", updateView);
  }, [getPbiAccessToken]);

  // console.log("pbiFilter", pbiFilter);

  return (
    <div className="Pbi__Box">
      {isReadyToRender && (
        <PowerBIEmbed
          embedConfig={{
            type: "report",
            id: id,
            embedUrl: "https://app.powerbi.com/reportEmbed/",
            pageName: pageName,
            accessToken: pbiAccessToken,
            tokenType: models.TokenType.Aad,
            filters: pbiFilter,
            settings: {
              layoutType: view,
              panes: {
                filters: { expanded: false, visible: false },
              },
            },
          }}
          eventHandlers={new Map([
            ["loaded", () => { }],
            ["rendered", () => { }],
            ["error", (event) => console.error(event.detail)],
          ])}
          cssClassName={"report-style-class"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pbiAccessToken: state?.pbiToken?.pbiAccessToken,
});

export default connect(mapStateToProps, { getPbiAccessToken })(PowerBi);

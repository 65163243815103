import React, { useState, useEffect, useRef } from "react";
import "./Homepage.css";
import TabComponent from "../Components/HomePage/TabComponent";
import MonthlyTourPlanning from "../Components/HomePage/MonthlyTourPlanning";
import MarketWorking from "../Components/HomePage/MarketWorking";
import Header from "../Components/Header";
import DivisionGroup from "../Components/HomePage/DivisionGroup";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";
import MyWssCounts from "../Components/HomePage/MyWssCounts";
import MyDealerCount from "../Components/HomePage/MyDealerCount";
import PowerBi from "./PowerBi";

function Homepage() {

  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);
  const [showModal, setShowModal] = useState(false);

  const userType = useSelector(
    (state) => state?.userDetails?.details?.Profile?.Name
  );

  const salesGroupCode = useSelector(
    (state) => state?.userDetails?.details?.Sales_Group_Code__c
  );

  const selectedSalesGroupCode = useSelector(
    (state) => state?.userDetails?.details?.Sales_Group_Code__c || state?.SelectedSalesGroupCode?.SalesGroupCode
  );

  const division = useSelector(
    (state) => state?.userDetails?.details?.Division
  );

  const HO_Role__c = useSelector((state) => state?.userType?.type)
  // to show modal when user is HO

  const userTypeAndRole = useSelector((state) =>
    state?.userType?.type != "Other"
      ? state?.userType?.type
      : state?.userDetails?.details?.Title
  );

  const [ pageId, setPageId] = useState("");

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!_.isEmpty(userType) && !_.isEmpty(HO_Role__c)) {
      if (HO_Role__c == "RBDM Collaborator" || HO_Role__c == "MH3") {
        setShowModal(false);
      }
      else if (userTypeAndRole && !pop_status && (userTypeAndRole === "HO Collaborator" || userTypeAndRole === "SH7")
        && (!salesGroupCode || division == 70 || division == 30)) {
        setShowModal(true);
        localStorage.setItem("pop_status", 1);
      }
      else if (userType !== "HO Colloaborator") {
        setShowModal(false);;
      } else {
        setShowModal(false);
      }
    }

  }, [userType, salesGroupCode, division, HO_Role__c]);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  const salesGroups = {
    105: "223cd674c97ddc2da176",
    111: "44fc726661060e5f4f3d",
    201: "7de9d97612731f282565",
    301: "89b5290e50834ee74325",
    401: "d5a214c4e18e6567daad",
    406: "e540c588abdd1363d6e1",
    901: "dde204f9b616fc883970",
    902: "25ed67a88267d3dcf2a2",
  };

  // Effect to check sales group code when it changes
  useEffect(() => {
    if (selectedSalesGroupCode) {
      if (salesGroups[selectedSalesGroupCode]) {
        setPageId(salesGroups[selectedSalesGroupCode]);
      } else {
        setPageId("");
      }
    }
  }, [selectedSalesGroupCode]);

  return (
    <div className="homepage container p-0">
      <Header />
      {pageId &&
        !(["RBDM Collaborator", "MH3", "HO Collaborator"].includes(userTypeAndRole)) ? (
        <div className="pbi__outerBox w-100 pbi_dashboard">
          <PowerBi
            id="c4018d9d-f992-490f-847e-b6d57e69a3f5"
            pageName={pageId}
          />
        </div>
      ) : (
        <>
          <TabComponent />
          <MonthlyTourPlanning />
          <MarketWorking />
          {userTypeAndRole === "SH3" && (
            <>
              <MyWssCounts />
              <MyDealerCount />
            </>
          )}
        </>
      )
      }
      {showModal && <DivisionGroup />}
    </div>
  );
}

export default Homepage;
